import React from "react";
import TwitterFeed from "./shared/components/TwitterFeed";
import ContactForm from "./shared/components/ContactForm";
import XLogo from "./media/XLogo.webp";

const Footer = () => {
  return (
    <>
      <div className="tertiary-color">
        <div className="h1 mx-3 py-5">Contáctenos</div>
        <div className="d-sm-flex flex-wrap">
          <div className="width-special order-2">
            <div className="text-center py-md-5">
              Email:{" "}
              <a href="mailto:contacto@tearri.biz">contacto@terari.biz</a>
            </div>
            <div className="py-4" id="contacto">
              <div className="h4 text-center">Déjanos un mensaje</div>
              <div className="w-75 mx-auto">
                <ContactForm />
              </div>
            </div>
          </div>
          <div className="width-special order-1">
            <div className="py-5 text-center h4">Síguenos en redes</div>
            <div className="d-flex justify-content-evenly">
              <a
                href="https://twitter.com/TERARIconsulti2"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  className="d-block "
                  src={XLogo}
                  alt="Digital"
                  style={{ height: "50px" }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/terari-s-a-s/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  className="d-block w-100"
                  src="./media/LinkedInLogo.webp"
                  alt="Digital"
                />
              </a>
            </div>
            <div className="text-center p-4">
              <h4 className="fw-bold">Dirección:</h4>
              <p className="h5" style={{ maxWidth: '500px', margin: '0 auto' }}>
                Edificio La Recoleta torre III, piso 6, oficina N° 61, calle Catalina Aldáz entre Portugal y Av. Eloy Alfaro, Quito D.M
              </p>
              <div className="mt-3" style={{ maxWidth: '500px', margin: '0 auto' }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.797895586846!2d-78.47938332423006!3d-0.18333259981484942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d59ba7674e86a1%3A0x60afeebf4654a9da!2sEdificio%20La%20Recoleta%20Torre%203!5e0!3m2!1ses!2sec!4v1737754163364!5m2!1ses!2sec"
                  width="100%"
                  height="225"
                  style={{ border: "0" }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Maps Location of Edificio La Recoleta Torre 3"
                />
              </div>
            </div>

          </div>
        </div>

        <p className="text-center p-5">
          Todos los derechos reservados por TERARI S.A.S. ©2022
        </p>
      </div>
    </>
  );
};

export default Footer;
